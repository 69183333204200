









import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import { iframeResizer, IFrameResizedData, IFrameComponent } from 'iframe-resizer';

@Component
export default class Popup extends Vue {

    @Ref('iframe')
    iframe!: IFrameComponent;

    private seatsActive = false;

    private iframeHeight = 200;

    private opened = false;

    private transitioning = false;

    private transitionStoppedListener: null | ((this: Popup) => void) = null;

    private transitionFallbackTimer = 0;

    private async mounted(): Promise<void> {

        iframeResizer({
            checkOrigin: false,
            minHeight: 200,
            resizedCallback: this.iframeResized,
            scrolling: true,
            sizeHeight: false,
        }, this.iframe);

        window.addEventListener('resize', this.setIframeHeight);

        this.transitionStoppedListener = () => {

            this.transitioning = false;
            window.clearTimeout(this.transitionFallbackTimer);

        };

        this.iframe.addEventListener('transitionend', this.transitionStoppedListener);
        this.iframe.addEventListener('transitioncancel', this.transitionStoppedListener);

        await this.$widget.initialized;

        this.$widget.client.onSeats((data: { state: 'created' | 'mounted' | 'destroy' | 'validation' }) => {

            switch (data.state) {

                case 'created':
                case 'mounted':
                case 'validation':
                    this.seatsActive = true;
                    break;
                default:
                    this.seatsActive = false;
                    break;

            }

            this.setIframeHeight();

        });

    }

    beforeDestroy(): void {

        if (this.transitionStoppedListener) {

            this.iframe.removeEventListener('transitionend', this.transitionStoppedListener);
            this.iframe.removeEventListener('transitioncancel', this.transitionStoppedListener);

        }

        this.transitionStoppedListener = null;

        this.transitioning = false;
        window.clearTimeout(this.transitionFallbackTimer);

    }

    private iframeResized(data: IFrameResizedData) {

        this.iframeHeight = data.height;

        this.setIframeHeight();

    }

    private setIframeHeight() {

        this.iframe.style.height = (window.innerWidth < 625 || this.seatsActive) ? '100%' : `${this.iframeHeight}px`;

    }

    private async onLoad(): Promise<void> {

        if (!this.iframe.contentWindow) {

            throw new Error('No access to iframe\'s content window');

        }

        await this.$widget.initClient(this.iframe.contentWindow);

    }

    @Watch('$widget.opened', { immediate: true })
    private onWidgetOpenedState(newState: boolean | null): void {

        if ((!newState) !== (!this.opened)) {

            window.clearTimeout(this.transitionFallbackTimer);

            this.transitioning = true;

            // Set a fallback here, there is a chance the transition won't start
            // and thus never finish/cancel, which would keep the popup inside the viewport.
            // The timeout is intentionally quite a bit larger than the transition time.
            this.transitionFallbackTimer = window.setTimeout(() => {

                this.transitioning = false;

            }, 2000);

        }

        this.opened = !!newState;

    }

}
