import Vue from 'vue';
import { CrossWindowClient } from '@openticket/sdk-shop/dist/messaging/index';
import { ShopSettingsClient } from '@openticket/sdk';
import type { CartData } from '@openticket/sdk-shop';
import { i18n } from './plugins';
import App from './App.vue';
import Style from './utils/style';

type CartCallback = (tickets: { [guid: string]: { selected: number } }) => void;
type OrderTotalCallback = (totalPrice: string) => void;
type OrderTotalRawCallback = (totalPrice: number, currency: string) => void;
type WidgetToggledCallback = (opened: boolean) => void;

export default class OpenTicketShopWidget {

    public initialized!: Promise<void>;

    public opened = false;

    public shopURL!: string;

    public shopId!: string;

    public client!: CrossWindowClient;

    private _vue!: Vue;

    private _resolveInitialized!: () => void;

    private _widgetToggledListener?: WidgetToggledCallback;

    private _cartTicketsUpdatedListener?: CartCallback;

    private _orderTotalUpdatedListener?: OrderTotalCallback;

    private _rawOrderTotalUpdatedListener?: OrderTotalRawCallback;

    private _lastCartData?: string;

    constructor() {

        this.initialized = new Promise((resolve) => {

            this._resolveInitialized = resolve;

        });

    }

    public async init(shopURL: string, shopId: string): Promise<void> {

        this.shopURL = shopURL;
        this.shopId = shopId;

        document.body.classList.add('ot-vars');
        const str = '<div id="ot-shop-container"></div>';
        document.body.insertAdjacentHTML('beforeend', str);

        this._vue = new Vue({
            i18n,
            render: (h) => h(App, {
                props: {
                    shopId,
                },
            }),
        }).$mount('#ot-shop-container');

        Style.initColorSchemeListener();

        return Promise.resolve();

    }

    public async initClient(window: Window): Promise<void> {

        this.client = new CrossWindowClient(window, { type: 'popup' });

        await this.client.connecting;

        this._resolveInitialized();

        this.client.onCartData((data: CartData) => {

            if (!this.cartDataChanged(data)) {

                return;

            }

            if (this._cartTicketsUpdatedListener) {

                const ticketData = Object.fromEntries(Object.entries(data.tickets).map(
                    ([ k, v ]) => [ k, { selected: v.count } ],
                ));

                this._cartTicketsUpdatedListener(ticketData);

            }

            if (this._orderTotalUpdatedListener) {

                const orderTotal: string = this._vue.$l.currency(
                    data.checkout_details.total_price,
                    this.client.localization.currency,
                );

                this._orderTotalUpdatedListener(orderTotal);

            }

            if (this._rawOrderTotalUpdatedListener) {

                this._rawOrderTotalUpdatedListener(data.checkout_details.total_price, this.client.localization.currency);

            }

        });

        this.client.onCustomMessage((data: { [key: string]: unknown }) => {

            if (data && data.type === 'closePopup') {

                this.close();

            }

        });

    }

    private cartDataChanged(data: CartData): boolean {

        const stringified: string = JSON.stringify(data);

        const equal: boolean = this._lastCartData === stringified;

        this._lastCartData = stringified;

        return equal;

    }

    public widgetToggled(cb: WidgetToggledCallback): void {

        this._widgetToggledListener = cb;

    }

    public cartTicketsUpdated(cb: CartCallback): void {

        this._cartTicketsUpdatedListener = cb;

    }

    public orderTotalUpdated(cb: OrderTotalCallback): void {

        this._orderTotalUpdatedListener = cb;

    }

    public rawOrderTotalUpdated(cb: OrderTotalRawCallback): void {

        this._rawOrderTotalUpdatedListener = cb;

    }

    public async addTicket(guid: string): Promise<void> {

        if (!guid) {

            throw Error('The ticket guid is required');

        }

        await this.initialized;
        this.client.sendMessage({ action: 'add', guid, type: 'ticket' });

    }

    public async removeTicket(guid: string): Promise<void> {

        if (!guid) {

            throw Error('The ticket guid is required');

        }

        await this.initialized;
        this.client.sendMessage({ action: 'remove', guid, type: 'ticket' });

    }

    public async addCoupon(code: string): Promise<void> {

        if (!code) {

            throw Error('The coupon code is required');

        }

        await this.initialized;

        this.client.sendMessage({ action: 'add', code, type: 'coupon' });

    }

    public async removeCoupon(code: string): Promise<void> {

        if (!code) {

            throw Error('The coupon code is required');

        }

        await this.initialized;

        this.client.sendMessage({ action: 'remove', code, type: 'coupon' });

    }

    public open(): void {

        this.opened = true;

        if (this._widgetToggledListener) {

            this._widgetToggledListener(true);

        }

    }

    public close(): void {

        this.opened = false;

        if (this._widgetToggledListener) {

            this._widgetToggledListener(false);

        }

    }

    public toggle(): void {

        this.opened = !this.opened;

        if (this._widgetToggledListener) {

            this._widgetToggledListener(this.opened);

        }

    }

}

window.OtShopWidget = new OpenTicketShopWidget();

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
Vue.prototype.$widget = window.OtShopWidget;

if (process.env.VUE_APP_SHOP_SETTINGS_API_URL) {

    // eslint-disable-next-line no-multi-assign, @typescript-eslint/no-unsafe-member-access
    Vue.prototype.$settings = window.OtShopSettings = Vue.observable(new ShopSettingsClient());

}
