import { RecursivePartial } from '@openticket/sdk-shop';
import { IShopSettingsStyle } from '@openticket/sdk';

export type AppliedStyleTheme = 'dark' | 'light';
export type StyleTheme = AppliedStyleTheme | 'dynamic';
export type ThemeChangeCallback = (theme: AppliedStyleTheme) => void;

export default class Style {

    /*
     * The order matters for priority in detecting themes
     * (dynamic can be applied in combination with dark or light)
     */
    public static themes: StyleTheme[] = [ 'dynamic', 'dark', 'light' ];

    private static _listeners: (ThemeChangeCallback | null)[] = [];

    public static initColorSchemeListener(): void {

        const matchMediaList: MediaQueryList = window.matchMedia(
            '(prefers-color-scheme: dark)',
        );

        if (!matchMediaList.addEventListener && matchMediaList.addListener) {

            matchMediaList.addListener(() => {

                if (this.getRawTheme() === 'dynamic') {

                    this.setTheme('dynamic');

                }

            });

        } else {

            matchMediaList.addEventListener('change', () => {

                if (this.getRawTheme() === 'dynamic') {

                    this.setTheme('dynamic');

                }

            });

        }

    }

    public static setTheme(theme: StyleTheme): void {

        if (!theme || !this.themes.includes(theme)) {

            return;

        }

        const oldtheme = this.getAppliedTheme();

        for (const t of this.themes) {

            document.body.classList.remove(`is-${t}`);

        }

        if (theme === 'dynamic') {

            if (!document.body.classList.contains('is-dynamic')) {

                document.body.classList.add('is-dynamic');

            }
            const colorScheme = this.getPreferColorScheme();
            document.body.classList.add(`is-${colorScheme}`);

        } else {

            document.body.classList.add(`is-${theme}`);

        }

        const newTheme = this.getAppliedTheme();

        if (newTheme === oldtheme) {

            return;

        }

        for (const cb of this._listeners) {

            if (cb) {

                cb(newTheme);

            }

        }

    }

    public static getPreferColorScheme(): AppliedStyleTheme {

        if (
            window.matchMedia
            && window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {

            return 'dark';

        }
        return 'light';

    }

    public static getRawTheme(): StyleTheme | undefined {

        let theme: StyleTheme | undefined;
        for (const t of this.themes) {

            if (document.body.classList.contains(`is-${t}`)) {

                theme = t;
                break;

            }

        }
        return theme;

    }

    public static getAppliedTheme(): AppliedStyleTheme {

        const theme = this.getRawTheme();

        switch (theme) {

            case 'dynamic':
                if (document.body.classList.contains('is-dark')) {

                    return 'dark';

                }
                return 'light';

            case 'dark':
                return 'dark';
            default:
                return 'light';

        }

    }

    public static addThemeListener(cb: ThemeChangeCallback): number {

        // Return index
        return this._listeners.push(cb) - 1;

    }

    public static removeThemeListener(i: number): void {

        this._listeners[i] = null;

    }

    public static publicSetStyle(style: RecursivePartial<IShopSettingsStyle>): void {

        this.setStyle(style as { [key: string]: string });

    }

    public static get(prop: string): string {

        const styles = window.getComputedStyle(document.body);
        return styles.getPropertyValue(`--${prop}`);

    }

    private static setStyle(
        style: { [key: string]: string },
        path: string[] = [],
    ) {

        for (const prop of Object.keys(style)) {

            const val:string = style[prop];
            if (val && typeof val === 'object') {

                Style.setStyle(val, [ ...path, prop ]);

            } else if (val && typeof val === 'string') {

                Style.writeToDom([ ...path, prop ].join('-'), val);

            }

        }

    }

    private static writeToDom(key: string, val: string) {

        const elem = document.getElementsByClassName(
            'ot-document',
        )[0] as HTMLElement;
        elem.style.setProperty(`--ot-${key}`, val);

    }

}
