







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import type { NotificationConfig } from '@openticket/vue-notifications';
import Popup from './components/Popup.vue';
import Button from './components/Button.vue';
import Style, { StyleTheme } from './utils/style';

@Component({
    components: {
        /* eslint-disable @typescript-eslint/no-unsafe-assignment */
        Button,
        Popup,
        /* eslint-enable @typescript-eslint/no-unsafe-assignment */
    },
})
export default class App extends Vue {

    @Prop() shopId!: string;

    @Ref('popup')
    popup!: Popup;

    @Ref('button')
    button!: Button;

    public async created(): Promise<void> {

        Vue.observable(this.$widget);

        await Promise.all([
            this.initLocalization(),
            this.initShopSettings(this.shopId),
        ]);

        window.addEventListener('click', (e) => {

            const target = e.target as HTMLElement;

            if (!target.className.includes('ot-notification') && (!this.button
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                || !this.button.buttonWrapper
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
                || !this.button.buttonWrapper.contains(target)) && (!this.popup
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                || !this.popup.iframe
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                || !this.popup.iframe.contains(target))
            ) {

                this.$widget.close();

            }

        });

        await this.$widget.initialized;

        this.$widget.client.onNotification((data: NotificationConfig) => {

            this.$notifications.show(data);

        });

    }

    private async initShopSettings(shopId: string): Promise<void> {

        if (!this.$settings) {

            return;

        }

        await this.$settings.init({
            baseUrl: process.env.VUE_APP_SHOP_SETTINGS_API_URL,
            shopId,
        });

        // Set custom style props
        if (this.$settings.static && this.$settings.static.style) {

            Style.publicSetStyle(this.$settings.static.style);

        }

        if (this.$settings.static && this.$settings.static.theme) {

            Style.setTheme(this.$settings.static && (this.$settings.static.theme) as StyleTheme);

        }

    }

    private async initLocalization(): Promise<void> {

        await this.$localization.init();

    }

}
