



































import Vue from 'vue';
import { Ref } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Style from '@/utils/style';

interface PartialCartData {
    tickets: { [guid: string]: { count: number; }; };
    products: { [guid: string]: { count: number; }; };
    // eslint-disable-next-line camelcase
    checkout_details: { total_price: number; };
}

@Component
export default class Button extends Vue {

    @Ref('buttonWrapper')
    buttonWrapper!: HTMLDivElement;

    @Ref('button')
    button!: HTMLDivElement;

    private connected = false;

    private ticketCount = 0;

    private price: number | null = null;

    private animateShake = false;

    private iconURL: string | null = null;

    private themeListenerId!: number;

    private created(): void {

        this.iconURL = this.getIconURL();

        this.themeListenerId = Style.addThemeListener(() => {

            this.iconURL = this.getIconURL();

        });

    }

    private destroy(): void {

        Style.removeThemeListener(this.themeListenerId);

    }

    private getIconURL(): string {

        let url!: string;
        if (Style.getAppliedTheme() === 'dark') {

            url = process.env.VUE_APP_WHITELABEL_ICON_URL;

        } else {

            url = process.env.VUE_APP_WHITELABEL_ICON_DARK_URL;

        }

        const { hostname } = new URL(this.$widget.shopURL);
        const whitelabel = hostname.split('.').splice(-2).join('.');

        return url.replace('{{whitelabel}}', whitelabel);

    }

    private async mounted() {

        await this.$widget.initialized;

        this.connected = this.$widget.client.isConnected;

        await this.$localization.setLocale(this.$widget.client.localization.locale);

        this.$widget.client.onLocale((data: { locale: string }) => {

            this.$localization.setLocale(data.locale).catch((err) => {

                console.error(err);

            });

        });

        this.$widget.client.onCartData((data: PartialCartData) => {

            this.animateShake = true;
            this.button.addEventListener('animationend', () => {

                this.animateShake = false;

            });

            if (data) {

                this.setTicketCount(data.tickets);

                const hasItems = Object.keys(data.tickets).length > 0 || Object.keys(data.products).length > 0;

                this.price = hasItems ? data.checkout_details.total_price : null;

            }

        });

    }

    private setTicketCount(tickets: PartialCartData['tickets']) {

        if (!Object.keys(tickets).length) {

            this.ticketCount = 0;
            return;

        }

        let count = 0;

        Object.values(tickets).forEach((ticket) => {

            count += ticket.count;

        });

        this.ticketCount = count;

    }

    get label(): string {

        if (this.price !== null) {

            return this.$t('widget.button_cart_label', {
                total: this.$l.currency(
                    this.price,
                    this.$widget.client.localization.currency,
                ),
            }) as string;

        }

        return this.$t('widget.button_cta_label') as string;

    }

}
